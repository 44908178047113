<template>
  <div class="flex flex-col w-full items-center" style="background: #E9E9E9;">
    <div class="w-full max-w-7xl pt-20 md:pt-10">
      <div class="flex flex-wrap">
        <div class="lg:w-6/6 w-full">
          <div class="flex flex-col contactus-bg text-black px-10 py-6 mb-8 lg:w-5/5 w-full rounded-l-lg shadow-2xl">
            <h3 class="pb-4 font-bold text-xl">Connect with us</h3>
            <a
              class="flex pt-2 pb-4 text-lg text-red-900
              hover:text-white hover:font-semibold hover:underline mr-auto"
              href="mailto:enquiry@mrc-asia.com"
            >
              <MailIcon class="mr-2" />
              Get in touch with us
            </a>
            <div class="py-2">
              <div class="font-bold">Singapore</div>
              <div>
                <span class="pr-4">T: (65) 6235 2852</span>|
                <span class="pl-4">Fax: (65) 6235 3937</span>
              </div>
              <a
                class="text-red-900 hover:text-white hover:font-semibold hover:underline"
                href="https://www.google.com/maps/place/Management+Resources+Consultants+(S)+Pte.+Ltd/@1.3061637,103.8297297,17z/data=!4m8!1m2!2m1!1s1+Scotts+Road,+%2326-01+Shaw+Centre,+Singapore+228208!3m4!1s0x31da198d12cf5563:0x2a7323dfc99319be!8m2!3d1.3062165!4d103.8317248"
                target="_blank"
              >
                1 Scotts Road, #26-01 Shaw Centre, Singapore 228208</a>
            </div>
            <div class="py-2">
              <div class="font-bold">Hong Kong</div>
              <div>T: (852) 9030 4213</div>
              <div>P.O. Box 12203 General Post Office Hong Kong</div>
            </div>
          </div>
        </div>
        <div class="lg:w-4/6 w-full max-w-6xl lg:pr-8 lg:pl-4 px-8">
          <h4 class="text-lg font-semibold pb-2">Subscribe to our e-newsletter</h4>
          <p class="">Stay tuned with our latest news, insights and promotions</p>
          <div class="flex flex-col w-full pt-4">
            <div class="w-full flex">
              <div class="py-1 w-2/5 pr-2">
                <label
                  for="name"
                  class="block text-sm font-medium leading-5 text-gray-700"
                >Name</label>
                <input
                  v-model="name"
                  id="name"
                  class="bg-gray-100 text-mt-1 form-input block w-full py-2 px-3 border
                  border-gray-400 shadow-sm focus:outline-none focus:shadow-outline-blue
                  focus:border-blue-300 transition duration-150 ease-in-out
                  sm:text-sm sm:leading-5 rounded-lg"
                >
              </div>
              <div class="py-1 w-2/5 pl-2">
                <label
                  for="fttitle"
                  class="block text-sm font-medium leading-5 text-gray-700"
                >Title</label>
                <input
                  v-model="title"
                  id="fttitle"
                  class="bg-gray-100 text-mt-1 form-input block w-full py-2 px-3 border
                  border-gray-400 shadow-sm focus:outline-none focus:shadow-outline-blue
                  focus:border-blue-300 transition duration-150 ease-in-out
                  sm:text-sm sm:leading-5 rounded-lg"
                >
              </div>
            </div>
            <div class="w-full flex">
              <div class="py-1 w-2/5 pr-2">
                <label
                  for="ftcompany"
                  class="block text-sm font-medium leading-5 text-gray-700"
                >Company</label>
                <input
                  v-model="company"
                  id="ftcompany"
                  class="bg-gray-100 text-mt-1 form-input block w-full py-2 px-3 border
                  border-gray-400 shadow-sm focus:outline-none focus:shadow-outline-blue
                  focus:border-blue-300 transition duration-150 ease-in-out
                  sm:text-sm sm:leading-5 rounded-lg"
                >
              </div>
              <div class="py-1 w-2/5 pl-2">
                <label
                  for="ftEmail"
                  class="block text-sm font-medium leading-5 text-gray-700"
                >Company Email</label>
                <input
                  v-model="email"
                  id="ftEmail"
                  class="bg-gray-100 text-mt-1 form-input block w-full py-2 px-3 border
                  border-gray-400 shadow-sm focus:outline-none focus:shadow-outline-blue
                  focus:border-blue-300 transition duration-150 ease-in-out
                  sm:text-sm sm:leading-5 rounded-lg"
                >
              </div>
              <div class="flex flex-col w-1/5 pl-4 justify-end py-1">
                <button
                  @click="submitSubscription"
                  type="button"
                  class="inline-flex justify-center py-2 px-4 border border-transparent
                    text-sm leading-5 font-medium text-white bg-red-600
                    focus:outline-none elevation-2 hover:elevation-6 active:elevation-1
                    transition duration-150 ease-in-out rounded-full"
                >Submit</button>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col lg:w-2/6 w-full lg:px-4 px-8 lg:pt-0 pt-8 lg:items-end">
          <div class="flex lg:justify-end max-w-md w-full">
            <img
              class="h-16 w-auto pt-2 max-w-xs"
              src="../assets/logo.svg"
              alt="MRC Logo"
            >
          </div>
          <div class="flex justify-between pt-8 lg:w-5/6 max-w-xs">
            <router-link to="/mrc#vision"
              class="font-bold text-sm"
            >Vision</router-link>
            <router-link
              class="font-bold text-sm"
              to="/mrc#mission"
            >Mission</router-link>
            <router-link
              class="font-bold text-sm"
              to="/mrc#ethos"
            >Ethos</router-link>
          </div>

          <div class="flex lg:justify-end pt-3 max-w-md w-full">
            <router-link
              class="font-bold text-sm mr-12"
              to="/join"
            >Grow with us</router-link>
            <router-link
              class="font-bold text-sm"
              to="/privacy"
            >Data Privacy</router-link>
          </div>

          <div class="flex pt-8 justify-between max-w-md w-full items-center">
            <!-- <span class="uppercase font-semibold text-lg w-1/3">Follow us</span> -->
            <div class="flex items-center lg:ml-auto lg:w-2/3 w-1/2 justify-between">
              <a
                target="_blank"
                href="https://www.linkedin.com/company/mrc-asia"
              >
                <LinkedinIcon />
              </a>
              <a
                target="_blank"
                href="https://www.facebook.com/mrcasia17/"
              >
                <FacebookIcon />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/mrc_asia/"
              >
                <InstagramIcon />
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCiqxY0VjyPKukeVJ8n90WPA/videos"
              >
                <YoutubeIcon />
              </a>
              <a
                target="_blank"
                href="https://www.flickr.com/photos/138568386@N02/albums"
              >
                <FlickrIcon />
              </a>
            </div>
          </div>
        </div>
        <div class="flex text-xs mt-10 mb-5 px-8 lg:px-4">
          Copyright © 2021 Management Resources Consultants (MRC-Asia). All rights reserved.
        </div>
      </div>
    </div>

    <Alert
      :bottom="alertBottom"
      :timeout="8000"
      v-model="subscriptionAlert"
    >
      <div class="px-6 py-4 lg:w-144 w-112 flex lg:text-base text-sm justify-between">
        <div class="pr-6">
          {{subscriptionMsg}}
        </div>
        <button
          @click="subscriptionAlert = false"
          class="text-pink-500 focus:outline-none font-medium text-sm"
        >CLOSE</button>
      </div>
    </Alert>
  </div>
</template>

<script>
import { subscribe } from '@/api/public/rpcs';
import recaptcha from '@/recaptcha';
import Alert from '@/components/Alert.vue';
import isEmailValid from '@/util/isEmailValid';
import MailIcon from './icons/MailIcon.vue';
import YoutubeIcon from './icons/YoutubeIcon.vue';
import FacebookIcon from './icons/FacebookIcon.vue';
import InstagramIcon from './icons/InstagramIcon.vue';
import FlickrIcon from './icons/FlickrIcon.vue';
import LinkedinIcon from './icons/LinkedinIcon.vue';

export default {
  data() {
    return {
      name: '',
      email: '',
      title: '',
      company: '',
      subscriptionAlert: false,
      subscriptionMsg: '',
      alertBottom: false,
    };
  },
  methods: {
    submitSubscription() {
      // check on name, email, title, company for empty string
      if (!this.name || !this.email || !this.company) {
        this.alertBottom = true;
        this.subscriptionAlert = true;
        this.subscriptionMsg = 'Please fill up your name, company and company email';
        return;
      }
      if (!isEmailValid(this.email)) {
        this.alertBottom = true;
        this.subscriptionAlert = true;
        this.subscriptionMsg = 'Please enter a valid company email';
        return;
      }

      recaptcha('subscribe')
        .then((token) => subscribe({
          name: this.name,
          email: this.email,
          title: this.title,
          company: this.company,
          recaptchaToken: token,
        }))
        .then(() => {
          this.name = '';
          this.email = '';
          this.title = '';
          this.company = '';
          this.alertBottom = true;
          this.subscriptionAlert = true;
          this.subscriptionMsg = 'Thank you for subscribing to MRC. You will be updated on our exciting programmes ahead!';
        })
        .catch((err) => {
          console.error(err);
          this.name = '';
          this.email = '';
          this.title = '';
          this.company = '';
          this.alertBottom = true;
          this.subscriptionAlert = true;
          this.subscriptionMsg = 'Error encountered. Please contact enquiry@mrc-asia.com to be updated on our exciting programmes ahead.';
        });
    },
  },
  components: {
    MailIcon,
    YoutubeIcon,
    FacebookIcon,
    InstagramIcon,
    FlickrIcon,
    LinkedinIcon,
    Alert,
    // TwitterIcon,
  },
};
</script>

<style scoped>
.contactus-bg {
  /* background-image: url('../assets/img/testimonial-bg.jpg'); */
  background: url('../assets/img/background/contactus.jpg') top no-repeat;
}
</style>
