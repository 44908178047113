import Vue from 'vue';

const screens = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
};

const sm = (val) => val >= screens.sm && val <= screens.md;
const md = (val) => val >= screens.md && val <= screens.lg;
const lg = (val) => val >= screens.lg && val <= screens.xl;
const xl = (val) => val >= screens.xl;

const getBreakpoint = (w) => {
  if (sm(w)) return 'sm';
  if (md(w)) return 'md';
  if (lg(w)) return 'lg';
  if (xl(w)) return 'xl';
  return 'all';
};
const debounce = (fn, wait = 1) => {
  let timeout;
  return function d(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn.call(this, ...args), wait);
  };
};

const breakpoints = Vue.observable({
  w: window.innerWidth,
  h: window.innerHeight,
  is: getBreakpoint(window.innerWidth),
});

window.addEventListener(
  'resize',
  debounce(() => {
    breakpoints.w = window.innerWidth;
    breakpoints.h = window.innerHeight;
    breakpoints.is = getBreakpoint(window.innerWidth);
  }, 200),
);

export default breakpoints;
