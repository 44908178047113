<template>
  <svg
    class="h-8 w-8 fill-current"
    version="1.1"
    id="Youtube_w_x2F__circle"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 20 20"
    enable-background="new 0 0 20 20"
    xml:space="preserve"
  >
    <path
      d="M11.603,9.833L9.357,8.785C9.161,8.694,9,8.796,9,9.013v1.974c0,0.217,
      0.161,0.319,0.357,0.228l2.245-1.048 C11.799,10.075,11.799,9.925,11.603,9.833z
      M10,0.4c-5.302,0-9.6,4.298-9.6,9.6s4.298,9.6,9.6,9.6s9.6-4.298,9.6-9.6
      S15.302,0.4,10,0.4z M10,13.9c-4.914,0-5-0.443-5-3.9s0.086-3.9,5-3.9s5,
      0.443,5,3.9S14.914,13.9,10,13.9z"
    />
  </svg>
</template>

<script>
export default {};
</script>
