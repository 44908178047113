<template>
  <div class="relative font-display">
    <header
      class="bg-white w-full h-24 flex items-center justify-between fixed z-50 top-0 shadow-sm"
    >
      <div class="flex items-center h-full xl:px-8 px-4">
        <router-link to="/" class="xl:h-16 h-12 xl:w-48 w-40 ml-2 lg:ml-0 flex items-center">
          <img class="h-full w-auto" src="./assets/logo.svg" alt="MRC Logo" />
        </router-link>
        <span class="text-white"></span>
      </div>

      <div
        class="h-full links w-256 justify-between
        text-sm hidden lg:flex"
      >
        <router-link
          v-for="(menu, x) in menus"
          :key="`header-${menu.text}`"
          :to="menu.url"
          @mouseenter.native="headerMenu = { ...headerMenu, [x]: true }"
          @mouseleave.native="headerMenu = { ...headerMenu, [x]: false }"
          @click.native="headerMenu = { ...headerMenu, [x]: false }"
          class="relative dropdown flex items-center
            hover:border-b-4 border-red-600 hover:font-medium mx-1"
        >
          <span class="text-lg font-menu" v-html="menu.text"></span>
          <template v-if="menu.submenus">
            <DropDownBtn class="pl-1" />
            <div
              class="absolute top-0 mt-24 z-50"
              :class="{
                'w-176': menu.submenus.length > 1,
                'w-88': menu.submenus.length === 1,
                [menu.align]: true,
                hidden: !headerMenu[x],
              }"
            >
              <div
                class="mt-1 elevation-2 bg-white
                cursor-default font-normal text-sm"
              >
                <div class="flex flex-row text-left">
                  <div
                    class="sublink flex flex-col p-2"
                    :class="subMenuWidth(menu.submenus.length)"
                    v-for="(submenu, i) in menu.submenus"
                    :key="i"
                  >
                    <router-link
                      @click.native="headerMenu = { ...headerMenu, [x]: false }"
                      v-for="(m, j) in submenu"
                      :key="`${i}-${j}`"
                      :to="m.url"
                      class="p-3 hover:bg-gray-300 rounded"
                    >
                      <span v-html="m.text"></span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </router-link>
      </div>

      <div
        class="lg:flex h-full items-center hidden xl:px-4
          ml-1 mr-4 font-semibold xl:text-base text-sm
          cursor-pointer relative"
        v-if="user.name && user.name !== 'MRC Video'"
        @mouseenter="userMenuVisible = true"
        @mouseleave="userMenuVisible = false"
      >
        {{ user.name }}
        <DropDownBtn class="pl-1" />
        <div v-show="userMenuVisible" class="absolute top-0 mt-24 z-20 left-0">
          <div
            class="mt-1 elevation-2 bg-white p-2
                cursor-default font-normal text-sm"
          >
            <div class="flex flex-col">
              <router-link
                @click.native="userMenuVisible = false"
                to="/online_services"
                class="p-3 hover:bg-gray-300 rounded cursor-pointer"
                >Online Services
              </router-link>
              <router-link
                @click.native="userMenuVisible = false"
                to="/references"
                class="p-3 hover:bg-gray-300 rounded cursor-pointer"
                >References
              </router-link>
              <div @click="changePassword()" class="p-3 hover:bg-gray-300 rounded cursor-pointer">
                Change Password
              </div>
              <div @click="signOut()" class="p-3 hover:bg-gray-300 rounded cursor-pointer">
                Log out
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else
        class="lg:flex hidden xl:px-4 ml-1 mr-4 font-medium xl:text-base text-sm items-center"
      >
        <a
          @click="setSignInVisible(true)"
          class="text-sm text-white ml-2 bg-red-600 md:block py-2 px-4 rounded-full cursor-pointer hover:border hover:border-yellow-200 hover:border-b-0 hover:shadow-lg"
          >Sign in</a
        >
        <div
          @click="scrollToBottom"
          class="text-sm text-white ml-2 bg-blue-600 md:block py-2 px-4 rounded-full cursor-pointer hover:border hover:border-yellow-200 hover:border-b-0 hover:shadow-lg"
        >
          Subscribe
        </div>
      </div>

      <MenuBtn
        class="lg:hidden p-1 mx-4 rounded text-black text-opacity-75
          hover:text-opacity-100 hover:bg-gray-300"
        @click="isMenuOpen = !isMenuOpen"
      />
    </header>

    <div class="bg-indigo-900 bg-opacity-0 text-center py-4 lg:px-4
    z-10 w-full fixed bottom-0" v-if="user.cp && cpVisible && user.name !== 'MRC Video'">
      <div class="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex
      lg:inline-flex" role="alert">
        <img style="cursor:pointer;" @click="cpVisible = false"
          class="w-4 h-4 mlr-2"
          :src="require('@/assets/img/close.svg')"
        >
        <span class="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold
        mr-3 ml-1">
          Notification ({{timerCount}})
        </span>
        <div class="flex lg:inline-flex" style="cursor:pointer;" @click="changePassword()">
          <span class="font-semibold mr-2 text-left flex-auto">
            Please change your password
          </span>
          <svg class="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20">
          <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8
          15.657l4.95-4.95z"/>
          </svg>
        </div>
      </div>
    </div>

    <div v-show="isMenuVisible" class="absolute top-0 left-0 right-0 p-2 z-50">
      <div class="flex flex-col border border-gray-400 bg-white rounded elevation-2 py-2">
        <div class="flex justify-between items-start px-2">
          <div class="h-12 w-40 flex items-center my-2 ml-2">
            <img class="h-full w-auto" src="./assets/logo.svg" alt="MRC Logo" />
          </div>
          <CloseBtn
            class="p-1 rounded text-black text-opacity-75
              hover:text-opacity-100 hover:bg-gray-300 mt-4"
            @click="isMenuOpen = !isMenuOpen"
          />
        </div>

        <div class="flex flex-col px-3 pt-3 pb-2 text-base items-center" v-if="user.name">
          <div class="flex items-center">
            Welcome,
            <h4 class="font-bold tracking-wide pl-2 py-2">{{ user.name }}</h4>
          </div>
          <router-link
            to="/online_services"
            @click.native="isMenuOpen = false"
            class="w-full text-center py-2 px-3
              bg-indigo-600 rounded text-white font-medium hover:bg-indigo-500"
            >Online Services
          </router-link>
          <div class="text-center py-2">
            <div
              @click="changePassword()"
              class="hover:underline rounded whitespace-no-wrap
                font-medium text-indigo-700 cursor-pointer"
            >
              Change Password
            </div>
            <div
              @click="signOut()"
              class="hover:underline rounded whitespace-no-wrap
                font-medium text-indigo-700 cursor-pointer"
            >
              Log out
            </div>
          </div>
        </div>

        <div class="flex flex-col px-3 pt-3 pb-2 text-base items-center" v-else>
          <router-link
            to="subscribe"
            @click.native="isMenuOpen = false"
            class="w-full text-center py-2 px-3
              bg-indigo-600 rounded text-white font-medium hover:bg-indigo-500"
            >Subscribe</router-link
          >
          <div class="text-center py-2">
            <span class="text-black text-opacity-75">Existing Customer? </span>
            <a
              @click="showSignIn()"
              class="hover:underline rounded whitespace-no-wrap
                font-medium text-indigo-700 cursor-pointer"
              >Sign in</a
            >
          </div>
        </div>

        <div class="">
          <template v-for="menu in menus">
            <div :key="`menu-${menu.text}`" class="menu px-2 border-t">
              <router-link
                :to="menu.url"
                @click.native="isMenuOpen = false"
                class="flex items-center
              hover:bg-gray-300 px-2 py-2 rounded my-2"
              >
                {{ removeBreak(menu.text) }}
              </router-link>
            </div>
            <div
              v-if="menu.submenus"
              class="flex flex-row text-left font-light pl-6 pb-4"
              :key="menu.text"
            >
              <div
                class="submenu flex flex-col w-1/2 pr-2"
                :class="subMenuWidth(menu.submenus.length)"
                v-for="(submenu, i) in menu.submenus"
                :key="i"
              >
                <router-link
                  v-for="(m, j) in submenu"
                  :key="`${i}-${j}`"
                  :to="m.url"
                  @click.native="isMenuOpen = false"
                  class="py-2 px-3 mb-1 hover:bg-indigo-100 rounded leading-tight"
                  >{{ m.text }}</router-link
                >
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <router-view class="relative overflow-auto inset-x-0 mt-24"></router-view>

    <Footer />

    <Modal :value="signInVisible" @input="setSignInVisible">
      <form class="flex flex-col bg-white px-8 pt-5 pb-4 sm:p-6 sm:pb-4 md:w-128 w-96 z-20">
        <div class="py-2 px-4">
          <label for="email" class="block text-sm font-normal leading-5 text-gray-700"
            ><span class="text-red-600">*</span> Email</label
          >
          <input
            type="email"
            autocomplete="email"
            @keyup.enter="signIn"
            id="email"
            name="email"
            required="true"
            v-model="email"
            class="mt-1 form-input block w-full py-2 px-3 border border-gray-400
                  shadow-sm focus:outline-none focus:shadow-outline-blue
                  focus:border-blue-400 hover:border-gray-500 transition duration-150 ease-in-out
                  sm:text-sm sm:leading-5 bg-gray-100"
          />
          <div v-show="emailEmpty" class="pt-1 text-xs text-red-600">
            Please enter email address
          </div>
        </div>
        <div class="py-2 px-4">
          <label for="password" class="block text-sm font-normal leading-5 text-gray-700"
            ><span class="text-red-600">*</span> Password</label
          >
          <input
            @keyup.enter="signIn"
            id="password"
            type="password"
            v-model="password"
            class="mt-1 form-input block w-full py-2 px-3 border border-gray-400
                  shadow-sm focus:outline-none focus:shadow-outline-blue
                  focus:border-blue-400 hover:border-gray-500 transition duration-150 ease-in-out
                  sm:text-sm sm:leading-5 bg-gray-100"
          />
          <div v-show="passwordEmpty" class="pt-1 text-xs text-red-600">
            Please enter your password
          </div>
        </div>
        <div class="pb-2 px-4">
          <a class="text-xs underline text-gray-700" href="/sign/forget">
            Forget Password?
          </a>
        </div>
        <div v-show="signInError" class="px-4 text-red-600 text-sm">
          <span v-if="signInError == 'max attempt'">
            You’ve reached the maximum logon attempts. Your account has been locked.
            Please contact (65) 6235 2852 for more information.
          </span>
          <span v-else>
            {{ signInError }}
          </span>
        </div>
        <button
          :disabled="emailEmpty || passwordEmpty"
          @click="signIn()"
          type="button"
          class="mx-4 mt-4 mb-4 justify-center py-2 px-4
                border border-transparent
                text-sm leading-5 font-medium text-white bg-red-600
                hover:bg-red-500 focus:outline-none focus:border-red-700
                focus:shadow-outline-red active:bg-red-700
                transition duration-150 ease-in-out"
        >
          Log in
        </button>
      </form>
    </Modal>
  </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500&family=Nunito+Sans:wght@500&family=Tilt+Warp&family=Varela&display=swap');
.links > .router-link-active {
  @apply border-b-4 font-medium;
}
.sublink > .router-link-exact-active {
  @apply bg-gray-400;
}
.menu > .router-link-active {
  @apply bg-gray-400;
}
.submenu > .router-link-exact-active {
  @apply bg-indigo-200;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
import breakpoints from '@/plugins/breakpoints';
import store from '@/store/index';
import DropDownBtn from './components/icons/DropDownBtn.vue';
import Modal from './components/Modal.vue';
import MenuBtn from './components/icons/MenuBtn.vue';
import CloseBtn from './components/icons/CloseBtn.vue';
import Footer from './components/Footer2.vue';
import { accessRights } from './api/private/rpcs';
import { signInCall } from './api/public/rpcs';

export default {
  name: 'App',
  components: {
    DropDownBtn,
    MenuBtn,
    CloseBtn,
    Footer,
    Modal,
  },
  data() {
    return {
      timerCount: 5,
      cpVisible: true,
      email: '',
      password: '',
      isMenuOpen: false,
      breakpoints,
      headerMenu: [],
      signInError: '',
      userMenuVisible: false,
      menus: Object.freeze([
        {
          text: 'Rewards & Org <br>Consulting',
          url: '/home',
          align: 'left-0',
          submenus: [
            [
              {
                text: 'Compensation Benchmarking',
                url: '/home/compensation_benchmarking',
              },
              {
                text: 'Executive Compensation Review & Recommendation',
                url: '/home/executive_compensation',
              },
              {
                text: 'Board of Directors’ Remuneration Advisory',
                url: '/home/directors_remuneration',
              },
              {
                text: 'Sales Incentive Plan Design',
                url: '/home/sales_incentive_plan',
              },
              {
                text: 'Stock, Phantom Stock & Deferred Cash Plan Design',
                url: '/home/stock_plan',
              },
              {
                text: 'Performance Appraisal and Bonus Distribution',
                url: '/home/performance_appraisal',
              },
            ],
            [
              {
                text: 'Relocation Package and Mobility Policy',
                url: '/home/relocation',
              },
              {
                text: 'M&A Compensation and Benefits Integration',
                url: '/home/ma_compensation_benefits_integration',
              },
              { text: 'Organisation Design', url: '/home/org_design' },
              { text: 'Talent Management', url: '/home/talent_management' },
              { text: 'Market Research', url: '/home/market_research' },
            ],
          ],
        },
        {
          url: '/predictive',
          text: 'AI <br>for HR',
          align: 'left-0',
          submenus: [
            [
              { text: 'Super Compensation (powered by AI)', url: '/tools/super_compensation' },
              {
                text: 'Employee Risk Predictor (ERP)',
                url: '/ai/employee_risk_predictor',
              },
              {
                text: 'Employee Salary Prediction',
                url: '/ai/employee_salary_predictor',
              },
              {
                text: 'AI HR Writer',
                url: '/ai/ai_content_generator',
              },
              {
                text: 'Other Predictive Analytics Services',
                url: '/ai/ai_others',
              },
            ],
          ],
        },
        {
          url: '/tools',
          text: ' Salary & Benefits <br>Benchmarking Data',
          align: 'left-0',
          submenus: [
            [
              { text: 'Super Compensation (powered by AI)', url: '/tools/super_compensation' },
              {
                text: 'Executive Compensation Survey (Private)',
                url: '/tools/executive_compensation_private',
              },
              {
                text: 'Board of Directors’ Remuneration (Public Listed)',
                url: '/tools/executive_compensation_public',
              },
              {
                text: 'Sales Incentive Market Practices Report',
                url: '/tools/sale_incentive_report',
              },
              {
                text: 'Expatriate Practice Report',
                url: '/tools/expat_practice_report',
              },
              {
                text: 'Industry Club / Group Customised Surveys',
                url: '/tools/group_surveys',
              },
              {
                text: 'Benefits Data Services - on demand',
                url: '/tools/benefits_data_services',
              },
            ],
            [
              {
                text: 'Employment Legislations',
                url: '/tools/employment_legislation',
              },
              {
                text: 'Relocation Calculator',
                url: '/tools/relocation_calculator',
              },
              {
                text: 'Pay Trends',
                url: '/tools/pay_trends',
              },
              {
                text: 'Forecast Predictor',
                url: '/tools/forecast_predictor',
              },
              {
                text: 'Productivity & Financials',
                url: '/tools/productivity_financials',
              },
              {
                text: 'HR Market',
                url: '/tools/hr_market',
              },
              {
                text: 'Economy',
                url: '/tools/economy',
              },
              {
                text: 'HR/C&B/Company News',
                url: '/tools/hr_cnb_company_news',
              },
            ],
          ],
        },
        {
          url: '/seminars',
          text: 'Symposium / <br>Seminars',
          align: 'left-0',
        },
        {
          url: '/market_insights',
          text: 'C&B Market <br>Insights',
          align: 'left-0',
          submenus: [
            [
              {
                text: 'Executive Business Intelligence Articles',
                url: '/market_insights/ebi',
              },
              // {
              //   text: 'Employment Legislation Updates',
              //   url: '/market_insights#employment_legislation_updates',
              // },
              // {
              //   text: 'C&B Market Insights Guides',
              //   url: '/market_insights#market_insights',
              // },
            ],
          ],
        },
        {
          url: '/book',
          text: 'Social <br>Responsibility',
          align: 'right-0',
        },
        {
          url: '/mrc',
          text: 'About Us /<br>Testimonial',
          align: 'right-0',
        },
      ]),
      browsingTimer: 0,
    };
  },
  mounted() {
    const interval = setInterval(() => {
      if (this.browsingTimer === 2) clearInterval(interval);
      this.browsingTimer += 1;
    }, 1000);
  },
  methods: {
    scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
    setSignInVisible(v) {
      store.signInVisible = v;
      if (!v) {
        store.signInGoTo = '';
      }
    },
    changePassword() {
      this.userMenuVisible = false;
      this.email = '';
      this.password = '';
      store.marketInsights = [];
      store.seminarEntries = [];
      window.location.href = '/sign/auth/cp?source=/home';
    },
    signOut() {
      this.userMenuVisible = false;
      this.email = '';
      this.password = '';
      store.marketInsights = [];
      store.seminarEntries = [];
      fetch('/sign/out').then(() => {
        store.user = { name: null, permissions: [] };
        if (this.$route.matched.some((record) => record.meta.requiresAuth)) {
          this.$router.replace('/home');
        }
      });
    },
    signIn() {
      this.signInError = '';
      signInCall({
        userid: this.email,
        password: this.password,
      })
        .then(() => {
          accessRights().then((data) => {
            store.user = data;
            store.signInVisible = false;
            if (store.signInGoTo) {
              // this.$router.push(store.signInGoTo);
              this.$router.push('/online_services');
              store.signInGoTo = '';
            } else {
              this.$router.push('/online_services');
            }
          });
        })
        .catch((err) => {
          if (err.message.startsWith('Unexpected token P')) {
            this.signInError = 'System not available. Please try again later.';
            return;
          }
          this.signInError = err.message;
        });
    },
    showSignIn() {
      this.isMenuOpen = false;
      store.signInVisible = true;
    },
    subMenuWidth(size) {
      if (size === 1) {
        return 'w-full';
      }
      if (size === 2) {
        return 'w-1/2';
      }
      if (size === 3) {
        return 'w-1/3';
      }
      return '';
    },
    removeBreak(text) {
      return text.replace('<br>', '');
    },
  },
  computed: {
    signInVisible: () => store.signInVisible,
    emailEmpty() {
      return !this.email || this.email.trim().length === 0;
    },
    passwordEmpty() {
      return !this.password || this.password.trim().length === 0;
    },
    isMenuVisible() {
      if (this.breakpoints.is === 'lg' || this.breakpoints.is === 'xl') {
        return false;
      }
      return this.isMenuOpen;
    },
    user() {
      return store.user;
    },
  },
  created() {
    accessRights()
      .then((data) => {
        store.user = data;
      })
      .catch(() => {
        if (this.$route.matched.some((record) => record.meta.requiresAuth)) {
          this.$router.replace('/home');
        }
      });
  },
  watch: {
    $route() {
      this.cpVisible = true;
      this.timerCount = 5;
    },
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount -= 1;
          }, 1000);
        } else {
          this.cpVisible = false;
        }
      },
      immediate: true,
    },
    browsingTimer(val) {
      if (val === 1) {
        const reloaded = localStorage.getItem('reloaded');
        if (reloaded !== 'true') {
          localStorage.setItem('reloaded', 'true');
          // console.log('window reload');
          window.location.reload();
        }
      }
      if (val > 1) {
        localStorage.setItem('reloaded', 'false');
        // console.log(val, localStorage.getItem('reloaded'));
      }
    },
  },

};
</script>
