<template>
  <svg
    class="h-8 w-8 fill-current"
    version="1.1"
    id="Facebook_w_x2F__circle"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 20 20"
    enable-background="new 0 0 20 20"
    xml:space="preserve"
  >
    <path
      d="M10,0.4c-5.302,0-9.6,4.298-9.6,9.6s4.298,9.6,9.6,9.6s9.6-4.298,
      9.6-9.6S15.302,0.4,10,0.4z M12.274,7.034 h-1.443c-0.171,0-0.361,
      0.225-0.361,0.524V8.6h1.805l-0.273,
      1.486H10.47v4.461H8.767v-4.461H7.222V8.6h1.545V7.726
      c0-1.254,0.87-2.273,2.064-2.273h1.443V7.034z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>

</style>
