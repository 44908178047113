import Vue from 'vue';

const user = {
  name: null,
  permissions: [],
};

const signInVisible = false;

const signInGoTo = '';
/* eslint-disable @typescript-eslint/no-explicit-any */
const marketInsights: any[] = [];
const seminarEntries: any[] = [];

const state = Vue.observable({
  user, signInVisible, signInGoTo, marketInsights, seminarEntries,
});
export default state;
