import Vue from 'vue';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router/index';
import './assets/styles/index.css';

Vue.config.productionTip = false;

/* eslint-disable @typescript-eslint/camelcase */
Vue.use(VueGtag, {
  config: {
    id: 'UA-122095152-1',
    cookie_prefix: 'gtag',
    cookie_domain: 'mrc-asia.com',
    cookie_expires: 28 * 24 * 60 * 60,
  },
}, router);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
