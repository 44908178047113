<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center z-0"
    v-show="value"
  >
    <div
      class="absolute h-full w-full bg-black z-30
        transition-opacity duration-150 ease-in-out"
      :class="opacity"
      @click="fadeOut()"
    >
    </div>
    <div class="z-40 transition transform duration-150 ease-in-out"
      :class="scale">
      <slot></slot>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      opacity: 'opacity-0',
      scale: 'scale-0',
    };
  },
  methods: {
    fadeOut() {
      this.opacity = 'opacity-0';
      this.scale = 'scale-0';
      setTimeout(() => {
        this.$emit('input', false);
      }, 100);
    },
  },
  watch: {
    value() {
      if (this.value) {
        setTimeout(() => {
          this.opacity = 'opacity-50';
          this.scale = '';
        }, 1);
      }
    },
  },
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
