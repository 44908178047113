<template>
  <transition v-if="!bottom" name="slide">
    <div
      v-if="value"
      class="fixed bg-gray-800 top-0 text-white
        flex self-center place-items-center rounded elevation-4"
    >
      <slot></slot>
    </div>
  </transition>
  <transition v-else name="slideup">
    <div
      v-if="value"
      class="fixed bg-gray-800 bottom-0 text-white
        flex self-center place-items-center rounded elevation-4"
    >
      <slot></slot>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    timeout: {
      type: Number,
      default: 0,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value() {
      if (!this.value || this.timeout <= 0) {
        return;
      }

      setTimeout(() => {
        this.$emit('input', false);
      }, this.timeout);
    },
  },
});
</script>

<style scoped>
.slide-enter-active {
  transition: all 0.3s;
}
.slide-leave-active {
  transition: all 0.3s;
}
.slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(-100px);
}
.slideup-enter-active {
  transition: all 0.3s;
}
.slideup-leave-active {
  transition: all 0.3s;
}
.slideup-enter, .slideup-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(100px);
}
</style>
