/* Do not change, this code is generated from Golang rpc-gen */

/* eslint-disable */

import {
  Subscription,
  BookSubscription,
  LoginUser,
} from './models';

export function signInCall(data: LoginUser) {
  return fetch('/sign/api/signin', {
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      if (response.ok) {
        return response;
      }
      
      const errMsg = await response.json();
      if (errMsg) {
        throw new Error(errMsg);  
      }
      throw new Error(`${response.statusText} : ${response.status}`);
    });
}

export function subscribe(data: Subscription) {
  return fetch('/api/subscribe/email', {
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      if (response.ok) {
        return response;
      }
      
      const errMsg = await response.json();
      if (errMsg) {
        throw new Error(errMsg);  
      }
      throw new Error(`${response.statusText} : ${response.status}`);
    });
}

export function request(data: Subscription) {
  return fetch('/api/subscribe/request', {
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      if (response.ok) {
        return response;
      }
      
      const errMsg = await response.json();
      if (errMsg) {
        throw new Error(errMsg);  
      }
      throw new Error(`${response.statusText} : ${response.status}`);
    });
}

export function ebi(data: Subscription) {
  return fetch('/api/subscribe/ebi', {
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      if (response.ok) {
        return response;
      }
      
      const errMsg = await response.json();
      if (errMsg) {
        throw new Error(errMsg);  
      }
      throw new Error(`${response.statusText} : ${response.status}`);
    });
}

export function book(data: BookSubscription) {
  return fetch('/api/subscribe/book', {
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      if (response.ok) {
        return response;
      }
      
      const errMsg = await response.json();
      if (errMsg) {
        throw new Error(errMsg);  
      }
      throw new Error(`${response.statusText} : ${response.status}`);
    });
}
