<template>
  <button
    @click="$emit('click',$event)"
    type="button"
    class="inline-flex focus:outline-none"
  >
    <svg
      class="h-6 w-6 stroke-current"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </button>
</template>

<script>
export default {};
</script>

<style>

</style>
