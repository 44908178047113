/* Do not change, this code is generated from Golang rpc-gen */

/* eslint-disable */

import {
  UserRights,
  SeminarMaterial,
  MarketInsight,
} from './models';

export function accessRights(): Promise<UserRights> {
  return fetch('/api/private/accessRights', {
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
  })
    .then(async (response) => {
      if (response.ok) {
        return response;
      }
      
      const errMsg = await response.json();
      if (errMsg) {
        throw new Error(errMsg);  
      }
      throw new Error(`${response.statusText} : ${response.status}`);
    })
    .then(response => response.json());
}

export function retrieveSeminarMaterials(): Promise<SeminarMaterial[]> {
  return fetch('/api/private/seminar/materials', {
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
  })
    .then(async (response) => {
      if (response.ok) {
        return response;
      }
      
      const errMsg = await response.json();
      if (errMsg) {
        throw new Error(errMsg);  
      }
      throw new Error(`${response.statusText} : ${response.status}`);
    })
    .then(response => response.json());
}

export function retrieveMarketInsights(data: string): Promise<MarketInsight[]> {
  return fetch('/api/private/marketInsights', {
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      if (response.ok) {
        return response;
      }
      
      const errMsg = await response.json();
      if (errMsg) {
        throw new Error(errMsg);  
      }
      throw new Error(`${response.statusText} : ${response.status}`);
    })
    .then(response => response.json());
}
