<template>
  <svg
    class="h-8 w-8 fill-current"
    version="1.1"
    id="Flickr_w_x2F__circle"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 20 20"
    enable-background="new 0 0 20 20"
    xml:space="preserve"
  >
    <path
      d="M10,0.4c-5.302,0-9.6,4.298-9.6,9.6s4.298,9.6,9.6,
      9.6s9.6-4.298,9.6-9.6S15.302,0.4,10,0.4z M7.436,12
      c-1.096,0-1.982-0.895-1.982-2s0.887-2,1.982-2c1.094,
      0,1.982,0.895,1.982,2S8.529,12,7.436,12z M12.565,12
      c-1.095,0-1.983-0.895-1.983-2s0.888-2,1.983-2c1.096,0,
      1.982,0.895,1.982,2S13.66,12,12.565,12z"
    />
  </svg>
</template>

<script>
export default {};
</script>
